import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from "constants/defaultValues";
import { useEffect, useState } from "react";

class StaticModel {
  constructor(
    id,
    type,
    shortDescription,
    longDescription,
    percentage,
    men,
    women,
    examples
  ) {
    this.id = id;
    this.type = type;
    this.shortDescription = shortDescription;
    this.longDescription = longDescription;
    this.percentage = percentage;
    this.men = men;
    this.women = women;
    this.examples = examples;
  }
}

export const connectionSlideSettings = {
  gap: 10,
  perView: 1,
  peek: { before: 0, after: 400 },
  rewind: false,
  hideNav: true,
};

export const wheelOfLifeDomains = [
  new StaticModel(1, "Work", "Your career and professional life."),
  new StaticModel(2, "Health", "Your physical and mental well-being."),
  new StaticModel(
    3,
    "Significant Other",
    "Your romantic or life partner relationship."
  ),
  new StaticModel(4, "Friends", "Your social connections and friendships."),
  new StaticModel(5, "Family", "Your relationships with family members."),
  new StaticModel(6, "Fun", "Your leisure domains and hobbies."),
  new StaticModel(7, "Finances", "Your financial stability and management."),
  new StaticModel(
    8,
    "Growth",
    "Your personal development and self-improvement."
  ),
  new StaticModel(9, "Spirit", "Your spiritual or inner life and beliefs."),
];

export class PhilosophyResultObject {
  constructor() {
    this.option1 = new PhilosophyOption("", "", "", "");
    this.option2 = new PhilosophyOption("", "", "", "");
    this.option3 = new PhilosophyOption("", "", "", "");
  }
}

export class PhilosophyOption {
  constructor(title, keyBeliefs, reasoning) {
    this.title = title;
    this.keyBeliefs = keyBeliefs;
    this.reasoning = reasoning;
  }
}

export const enneagramList = [
  new StaticModel(
    1,
    "Type 1",
    "The Reformer",
    "Rational, idealistic, principled, purposeful, self-controlled, and perfectionistic.",
    8.9,
    10,
    9,
    "Nelson Mandela, Michelle Obama, Prince Charles, Kate Middleton, Margaret Thatcher, Joan Baez, Noam Chomsky"
  ),
  new StaticModel(
    2,
    "Type 2",
    "The Helper",
    "Caring, interpersonal, generous, demonstrative, people-pleasing, and possessive.",
    8.5,
    6,
    10,
    "Eleanor Roosevelt, Nancy Reagan, Monica Lewinsky, John Denver, Lionel Richie, Elizabeth Taylor"
  ),
  new StaticModel(
    3,
    "Type 3",
    "The Achiever",
    "Success-oriented, adaptive, excelling, driven, and image-conscious.",
    10.5,
    14,
    10,
    "Bill Clinton, Tony Blair, Arnold Schwarzenegger, Elvis Presley, Madonna, Lady Gaga, Taylor Swift, Tom Cruise, Richard Gere"
  ),
  new StaticModel(
    4,
    "Type 4",
    "The Individualist",
    "Sensitive, withdrawn, expressive, dramatic, self-absorbed, and temperamental.",
    15,
    10,
    18,
    "Rumi, Frédéric Chopin, Virginia Woolf, Anne Frank, Ingmar Bergman, Bob Dylan, Leonard Cohen, Paul Simon, Marlon Brando, Nicolas Cage, Kate Winslet, Angelina Jolie, Johnny Depp"
  ),
  new StaticModel(
    5,
    "Type 5",
    "The Investigator",
    "Intense, cerebral, perceptive, innovative, secretive, and isolated.",
    4.8,
    7,
    3,
    "Albert Einstein, Vincent van Gogh, Salvador Dali, Friedrich Nietzsche, Stephen Hawking, Agatha Christie, James Joyce, Bill Gates, Mark Zuckerberg, Julian Assange"
  ),
  new StaticModel(
    6,
    "Type 6",
    "The Loyalist",
    "Engaging and responsible but can be anxious and suspicious.",
    16.1,
    13,
    18,
    "Marilyn Monroe, Prince Harry, Mark Twain, Sarah Jessica Parker, Princess Diana, Kristen Stewart, Jennifer Aniston, Ben Affleck, Ellen Degeneres, Daniel Craig, Bruce Springsteen, Eminem, Britney Spears, J.R.R. Tolkien"
  ),
  new StaticModel(
    7,
    "Type 7",
    "The Enthusiast",
    "Spontaneous and versatile but can be acquisitive and scattered.",
    13.7,
    19,
    12,
    "Elton John, John F. Kennedy, Miley Cyrus, Britney Spears, Russell Brand, Katy Perry, Tiffany Haddish, Sacha Baron Cohen, Robin Williams, Jim Carrey,  Cameron Diaz, Eddie Murphy, Richard Branson, Steven Spielberg, Conan O'Brien, Andy Samberg, Ted Danson, Elizabeth Taylor"
  ),
  new StaticModel(
    8,
    "Type 8",
    "The Challenger",
    "Powerful, dominating, self-confident, decisive, willful, and confrontational.",
    6.3,
    8,
    6,
    "Franklin D. Roosevelt, Winston Churchill, Fidel Castro, Martin Luther King, Jr., Mikhail Gorbachev, Pablo Picasso, Ernest Hemingway, Toni Morrison, Serena Williams"
  ),
  new StaticModel(
    9,
    "Type 9",
    "The Peacemaker",
    "Easygoing, self-effacing, receptive, reassuring, agreeable, and complacent.",
    16.2,
    16,
    17,
    "Queen Elizabeth I, Ronald Reagan, George W. Bush, John F. Kennedy, Jr, Claude Monet, Gary Cooper, Audrey Hepburn, Sophia Loren"
  ),
];

export const mbtiList = [
  new StaticModel(
    1,
    "INTJ",
    "The Architect",
    "Imaginative and strategic thinkers, with a plan for everything.",
    2.1,
    3.3,
    0.9,
    "Elon Musk, Stephen Hawking, Isaac Newton, Mark Zuckerberg, Michelle Obama, Arnold Schwarzenegger, Bill Gates, Ayn Rand, Vladimir Putin, Hillary Clinton"
  ),
  new StaticModel(
    2,
    "INTP",
    "The Logician",
    "Innovative inventors with an unquenchable thirst for knowledge.",
    3.3,
    4.8,
    1.7,
    "Albert Einstein, Bill Nye, Marie Curie, Charles Darwin, Abraham Lincoln, Ellen Page, Richard Dawkins, Kristen Stewart, Stanley Kubrick, Neil Gaiman"
  ),
  new StaticModel(
    3,
    "ENTJ",
    "The Commander",
    "Bold, imaginative and strong-willed leaders, always finding a way – or making one.",
    1.8,
    2.7,
    0.9,
    "Steve Jobs, Margaret Thatcher, Bill Gates, Elon Musk, Hillary Clinton, Gordon Ramsay, Franklin D. Roosevelt, Jim Carrey, Vince Lombardi, Richard Branson"
  ),
  new StaticModel(
    4,
    "ENTP",
    "The Debater",
    "Smart and curious thinkers who cannot resist an intellectual challenge.",
    3.2,
    4,
    2.4,
    "Thomas Edison, Leonardo da Vinci, Benjamin Franklin, Steve Wozniak, Mark Twain, Elon Musk, Neil Patrick Harris, Russell Brand, Celine Dion, Richard Feynman"
  ),
  new StaticModel(
    5,
    "INFJ",
    "The Advocate",
    "Quiet and mystical, yet very inspiring and tireless idealists.",
    1.5,
    1.2,
    1.6,
    "Martin Luther King Jr., Nelson Mandela, Mahatma Gandhi, Mother Teresa, Oprah Winfrey, Nicole Kidman, Cate Blanchett, Plato, Carl Jung, Emily Brontë"
  ),
  new StaticModel(
    6,
    "INFP",
    "The Mediator",
    "Poetic, kind and altruistic people, always eager to help a good cause.",
    4.4,
    7.6,
    4.6,
    "William Shakespeare, J.R.R. Tolkien, Princess Diana, Johnny Depp, Björk, Vincent van Gogh, Tim Burton, Kurt Cobain, George Orwell, A.A. Milne"
  ),
  new StaticModel(
    7,
    "ENFJ",
    "The Protagonist",
    "Charismatic and inspiring leaders, able to mesmerize their listeners.",
    2.5,
    1.6,
    3.3,
    "Barack Obama, Oprah Winfrey, Martin Luther King Jr., Nelson Mandela, Tony Robbins, Reese Witherspoon, Emma Stone, Morgan Freeman, Anne Hathaway, Hugh Jackman"
  ),
  new StaticModel(
    8,
    "ENFP",
    "The Campaigner",
    "Enthusiastic, creative and sociable free spirits, who can always find a reason to smile.",
    8.1,
    6.4,
    9.7,
    "Mark Twain, Robin Williams, Walt Disney, Quentin Tarantino, Sandra Bullock, Ellen DeGeneres, Oscar Wilde, Anne Frank, Janelle Monáe, Robert Downey Jr."
  ),
  new StaticModel(
    9,
    "ISTJ",
    "The Logistician",
    "Practical and fact-minded individuals, whose reliability cannot be doubted.",
    11.6,
    16.4,
    6.9,
    "Queen Elizabeth II, Warren Buffett, Angela Merkel, George Washington, Jeff Bezos, Condoleezza Rice, George H. W. Bush, J.K. Rowling, John D. Rockefeller, Anthony Hopkins"
  ),
  new StaticModel(
    10,
    "ISFJ",
    "The Defender",
    "Very dedicated and warm protectors, always ready to defend their loved ones.",
    13.8,
    8.1,
    19.4,
    "Mother Teresa, Prince Charles, Kate Middleton, Rosa Parks, Jimmy Carter, Queen Elizabeth The Queen Mother, Barbara Walters, Halle Berry, Laura Bush, Beyoncé"
  ),
  new StaticModel(
    11,
    "ESTJ",
    "The Executive",
    "Excellent administrators, unsurpassed at managing things – or people.",
    8.7,
    11.2,
    6.3,
    "Margaret Thatcher, George W. Bush, Lyndon B. Johnson, Vince Lombardi, James Monroe, Hugh Jackman, Martha Stewart, Judge Judy, David Letterman, Frank Sinatra"
  ),
  new StaticModel(
    12,
    "ESFJ",
    "The Consul",
    "Extraordinarily caring, social and popular people, always eager to help.",
    12,
    7.5,
    16.9,
    "Taylor Swift, Bill Clinton, Barbara Walters, Jennifer Garner, Nancy Reagan, Harry S. Truman, Tyra Banks, Jamie Foxx, Danny Glover, Hugh Jackman"
  ),
  new StaticModel(
    13,
    "ISTP",
    "The Virtuoso",
    "Bold and practical experimenters, masters of all kinds of tools.",
    5.4,
    8.5,
    2.3,
    "Clint Eastwood, Steve Jobs, Bruce Lee, Ernest Hemingway, Amelia Earhart, Keith Richards, Bear Grylls, Tom Cruise, Chuck Yeager, Scarlett Johansson"
  ),
  new StaticModel(
    14,
    "ISFP",
    "The Adventurer",
    "Flexible and charming artists, always ready to explore and experience something new.",
    8.8,
    7.6,
    9.9,
    "Princess Diana, Michael Jackson, Ulyana Sergeenko, Paul McCartney, Audrey Hepburn, Jacqueline Kennedy Onassis, Lana Del Rey, Steven Spielberg, Avril Lavigne, Lady Gaga"
  ),
  new StaticModel(
    15,
    "ESTP",
    "The Entrepreneur",
    "Smart, energetic and very perceptive people, who truly enjoy living on the edge.",
    4.3,
    5.6,
    3,
    "Winston Churchill, Madonna, Donald Trump, Ernest Hemingway, Jack Nicholson, Richard Branson, Thomas Edison, Gordon Ramsay, Hugh Hefner, Tina Turner"
  ),
  new StaticModel(
    16,
    "ESFP",
    "The Entertainer",
    "Spontaneous, energetic and enthusiastic people – life is never boring around them.",
    8.5,
    6.9,
    10.1,
    "Marilyn Monroe, Elvis Presley, Jamie Oliver, Jennifer Lawrence, Katy Perry, Hugh Jackman, Freddie Mercury, Bill Clinton, Adele, Miley Cyrus"
  ),
];

export const gallupStrengthsList = [
  new StaticModel(
    1,
    "Achiever",
    "Driven to achieve",
    "Constant need for achievement."
  ),
  new StaticModel(
    2,
    "Activator",
    "Turns thoughts into action",
    "Eager to act, impatient for action."
  ),
  new StaticModel(
    3,
    "Adaptability",
    "Lives in the moment",
    "Flexible, takes things as they come."
  ),
  new StaticModel(
    4,
    "Analytical",
    "Seeks reasons and causes",
    "Thinks about all the factors that might affect a situation."
  ),
  new StaticModel(
    5,
    "Arranger",
    "Organizes, but also flexible",
    "Can organize, but has a flexibility that complements this ability."
  ),
  new StaticModel(
    6,
    "Belief",
    "Strong core values",
    "Has certain core values that are unchanging."
  ),
  new StaticModel(
    7,
    "Command",
    "Takes control",
    "Takes charge, is not afraid of confrontation."
  ),
  new StaticModel(
    8,
    "Communication",
    "Good with words",
    "Finds it easy to put thoughts into words."
  ),
  new StaticModel(
    9,
    "Competition",
    "Measures progress against others",
    "Needs to compare and compete."
  ),
  new StaticModel(
    10,
    "Connectedness",
    "Sees the bigger picture",
    "Sees the links between all things."
  ),
  new StaticModel(
    11,
    "Consistency",
    "Keen on equality",
    "Aware of the need to treat people the same."
  ),
  new StaticModel(
    12,
    "Context",
    "Looks back to understand the present",
    "Looks back to understand the reasons why."
  ),
  new StaticModel(
    13,
    "Deliberative",
    "Careful and vigilant",
    "Takes serious care in making decisions or choices."
  ),
  new StaticModel(
    14,
    "Developer",
    "Sees potential in others",
    "Recognizes and cultivates the potential in others."
  ),
  new StaticModel(
    15,
    "Discipline",
    "Enjoys routine and structure",
    "Enjoys routine and structure in their life."
  ),
  new StaticModel(
    16,
    "Empathy",
    "Can sense others' feelings",
    "Can sense the feelings of other people by imagining themselves in others’ lives."
  ),
  new StaticModel(
    17,
    "Focus",
    "Has clear goals",
    "Can take a direction, follow through, and make the corrections necessary to stay on track."
  ),
  new StaticModel(
    18,
    "Futuristic",
    "Inspired by the future",
    "Inspired by what could be and what they can imagine."
  ),
  new StaticModel(
    19,
    "Harmony",
    "Seeks consensus",
    "Looks for areas of agreement."
  ),
  new StaticModel(
    20,
    "Ideation",
    "Fascinated by ideas",
    "Can find connections between seemingly disparate phenomena."
  ),
  new StaticModel(
    21,
    "Includer",
    "Accepts others",
    "Shows awareness of those who feel left out."
  ),
  new StaticModel(
    22,
    "Individualization",
    "Intrigued by unique qualities",
    "Intrigued by the unique qualities of each person."
  ),
  new StaticModel(
    23,
    "Input",
    "Craves to know more",
    "Has a craving to know more, often collecting and archiving all kinds of information."
  ),
  new StaticModel(
    24,
    "Intellection",
    "Loves to think",
    "Loves to think and mull over things."
  ),
  new StaticModel(
    25,
    "Learner",
    "Has a great desire to learn",
    "Has a great desire to learn and wants to continuously improve."
  ),
  new StaticModel(
    26,
    "Maximizer",
    "Focuses on strengths",
    "Focuses on strengths as a way to stimulate personal and group excellence."
  ),
  new StaticModel(
    27,
    "Positivity",
    "Has contagious enthusiasm",
    "Has an infectious energy and enthusiasm."
  ),
  new StaticModel(
    28,
    "Relator",
    "Enjoys close relationships",
    "Enjoys close relationships with others."
  ),
  new StaticModel(
    29,
    "Responsibility",
    "Takes psychological ownership",
    "Takes psychological ownership of what they say they will do."
  ),
  new StaticModel(
    30,
    "Restorative",
    "Good at dealing with problems",
    "Loves to solve problems."
  ),
  new StaticModel(
    31,
    "Self-Assurance",
    "Confident in their ability",
    "Feels confident in their ability to manage their own lives."
  ),
  new StaticModel(
    32,
    "Significance",
    "Wants to make a big impact",
    "Wants to be very important in the eyes of others."
  ),
  new StaticModel(
    33,
    "Strategic",
    "Sees patterns where others see complexity",
    "Can sort through the clutter and find the best route."
  ),
  new StaticModel(
    34,
    "Woo",
    "Loves meeting new people",
    "Loves the challenge of meeting new people and winning them over."
  ),
];

export const humanValuesList = [
  // ... previous values ...
  new StaticModel(
    8,
    "Wealth",
    "Money, material goods, luxury lifestyle",
    "Aspiring for financial abundance and the ability to afford a luxurious lifestyle."
  ),
  new StaticModel(
    9,
    "Financial Stability",
    "Stable income, financial freedom",
    "Seeking consistent and reliable financial resources for security and freedom."
  ),
  new StaticModel(
    10,
    "Pleasure",
    "Enjoyment, happiness, satisfaction",
    "Pursuing domains and experiences that bring joy and satisfaction."
  ),
  new StaticModel(
    11,
    "Tradition",
    "Respecting customs, practicing rites",
    "Valuing long-standing practices and beliefs that are handed down from past generations."
  ),
  new StaticModel(
    12,
    "Trust",
    "A belief in someone or something",
    "Having confidence in the reliability and truth of others."
  ),
  new StaticModel(
    13,
    "Privacy",
    "The right to have secrets, discretion",
    "Valuing personal space and the right to withhold personal information."
  ),
  new StaticModel(
    14,
    "Accountability",
    "Responsibility, dependability",
    "Feeling a sense of duty and responsibility for one's actions or decisions."
  ),
  new StaticModel(
    15,
    "Challenge",
    "Testing your own abilities, competition",
    "Enjoying testing one’s abilities and competing against others."
  ),
  new StaticModel(
    16,
    "Adventure",
    "Looking for exciting and risky domains",
    "Seeking new and exciting experiences, often involving risk."
  ),
  new StaticModel(
    17,
    "Variety",
    "Frequent change, diversity",
    "Appreciating diverse experiences and frequent change."
  ),
  new StaticModel(
    18,
    "Excitement",
    "Great enthusiasm, eagerness",
    "Seeking thrilling experiences and a sense of exhilaration."
  ),
  new StaticModel(
    19,
    "Courage",
    "Fearlessness, bravery",
    "Facing challenges boldly and overcoming fears."
  ),
  new StaticModel(
    20,
    "Curiosity",
    "Willingness to explore and learn",
    "Having a strong desire to learn and explore new things."
  ),
  new StaticModel(
    21,
    "Creativity",
    "Imagination, inventiveness",
    "Valuing originality and the ability to create new and innovative ideas."
  ),
  new StaticModel(
    22,
    "Reputation",
    "Other people's opinion",
    "Concern for how others view one’s status or achievements."
  ),
  new StaticModel(
    23,
    "Respect",
    "Honour and care shown towards someone",
    "Showing consideration and recognition of others' worth or abilities."
  ),
  new StaticModel(
    24,
    "Authority",
    "The right to lead and control",
    "Valuing the power to give orders and enforce obedience."
  ),
  new StaticModel(
    25,
    "Fame",
    "Being known or recognized by many people",
    "The desire to be known widely and positively by a broad audience."
  ),
  new StaticModel(
    26,
    "Authenticity",
    "Truthfulness, genuineness",
    "Being true to one's own personality, spirit, and character."
  ),
  new StaticModel(
    27,
    "Beauty",
    "Aesthetics, attractiveness",
    "Appreciating aesthetic qualities and seeking beauty in all its forms."
  ),
  new StaticModel(
    28,
    "Appreciation",
    "Recognition of value or worth",
    "Recognizing and valuing the contributions and achievements of others."
  ),
  new StaticModel(
    29,
    "Acceptance",
    "Being included, approval",
    "The desire for inclusion and approval from others or society."
  ),
  new StaticModel(
    30,
    "Influence",
    "Having effect on people or things",
    "The capacity to have an effect on the character, development, or behavior of someone or something."
  ),
  new StaticModel(
    31,
    "Popularity",
    "Being liked or admired by many people",
    "The desire to be liked and admired by a large number of people."
  ),
  new StaticModel(
    32,
    "Uniqueness",
    "Being particularly remarkable or unusual",
    "Valuing individuality and the desire to stand out as being different."
  ),
  new StaticModel(
    33,
    "Love",
    "Deep feeling of affection",
    "Valuing deep, affectionate relationships."
  ),
  new StaticModel(
    34,
    "Family",
    "Caring for loved ones",
    "Placing importance on family ties and relationships."
  ),
  new StaticModel(
    35,
    "Honesty",
    "Sincerity, frankness",
    "Valuing truthfulness and straightforwardness in communication."
  ),
  new StaticModel(
    36,
    "Loyalty",
    "Faithfulness",
    "Being faithful and devoted to someone or something."
  ),
  new StaticModel(
    37,
    "Forgiveness",
    "Willingness to forgive others",
    "The capacity to forgive and not hold grudges."
  ),
  new StaticModel(
    38,
    "Compassion",
    "Empathy, sympathy",
    "Feeling empathy and concern for the sufferings or misfortunes of others."
  ),
  new StaticModel(
    39,
    "Friendship",
    "Comradeship, companionship",
    "Valuing bonds of mutual affection and understanding with friends."
  ),
  new StaticModel(
    40,
    "Growth",
    "Physical, intellectual and spiritual development",
    "Pursuing personal development in various aspects of life."
  ),
  new StaticModel(
    41,
    "Passion",
    "A vocation or loved hobby",
    "Having a strong and barely controllable emotion toward a compelling interest or activity."
  ),
  new StaticModel(
    42,
    "Excellence",
    "Mastery, perfection",
    "Striving for outstanding or superior performance in various fields."
  ),
  new StaticModel(
    43,
    "Determination",
    "Persistence, perseverance",
    "Having firmness of purpose and the resolve to achieve goals."
  ),
  new StaticModel(
    44,
    "Success",
    "Achieving desired results",
    "Achieving goals and meeting personal or professional benchmarks."
  ),
  new StaticModel(
    45,
    "Independence",
    "Self-reliance, self-sufficiency",
    "Valuing the ability to think and act without others' aid."
  ),
  new StaticModel(
    46,
    "Religion",
    "Devoutness, faith",
    "Valuing religious beliefs and practices."
  ),
  new StaticModel(
    47,
    "Wisdom",
    "Making good decisions and judgements",
    "Valuing insight and the ability to make sound judgments."
  ),
  new StaticModel(
    48,
    "Intelligence",
    "Logical thinking, quick learning",
    "Valuing intellectual capacity and the ability to understand complex ideas."
  ),
  new StaticModel(
    49,
    "Competence",
    "Capability, expertise, skill",
    "Valuing expertise and skill in one's profession or personal endeavors."
  ),
  new StaticModel(
    50,
    "Spirituality",
    "Focusing on intangible aspects of life",
    "Valuing the non-material aspects of life and personal inner beliefs."
  ),
  new StaticModel(
    51,
    "Inner Harmony",
    "Balance, self-fulfilment",
    "Seeking personal peace and a sense of fulfillment."
  ),
  new StaticModel(
    52,
    "Ambition",
    "Aspiration, strong desire to succeed",
    "Having a strong desire to achieve success."
  ),
  new StaticModel(
    53,
    "Contribution",
    "Helping others to achieve a common goal",
    "Aiming to contribute to the welfare or betterment of others."
  ),
  new StaticModel(
    54,
    "Equality",
    "Equal rights and opportunities for all",
    "Valuing fairness and equal opportunities for all individuals."
  ),
  new StaticModel(
    55,
    "Justice",
    "Fairness, integrity",
    "Upholding moral righteousness and fair treatment."
  ),
  new StaticModel(
    56,
    "Meaningful Work",
    "Positive impact on other people's lives",
    "Engaging in work that has a significant and beneficial impact on others."
  ),
  new StaticModel(
    57,
    "Teamwork",
    "Collaboration, synergy",
    "Working effectively and cooperatively with others."
  ),
  new StaticModel(
    58,
    "Tolerance",
    "Acceptance, openness, open-mindedness",
    "Being open and accepting of different perspectives and practices."
  ),
  new StaticModel(
    59,
    "Commitment",
    "Engagement, support",
    "Being dedicated to a cause, activity, or relationship."
  ),
  new StaticModel(
    60,
    "Ethics",
    "Moral behavior",
    "Upholding principles of right and wrong behavior."
  ),
  new StaticModel(
    61,
    "Helpfulness",
    "Benevolence, service, altruism",
    "Being inclined to offer assistance and support to others."
  ),
  new StaticModel(
    62,
    "Freedom",
    "Liberty, freedom of action and thought",
    "Valuing the ability to act, speak, or think without undue external restrictions."
  ),
];

export const humanDesignTypes = [
  new StaticModel(
    1,
    "Manifesting Generator",
    "A type that combines the traits of both Manifestors and Generators",
    "Known for their energy and ability to initiate things.",
    37,
    50,
    50,
    "Serena Williams, Elon Musk, Richard Branson, Oprah Winfrey, Jim Carrey, Steve Jobs, Mark Zuckerberg, Madonna, Dalai Lama, Maya Angelou"
  ),
  new StaticModel(
    2,
    "Generator",
    "Energetic and responsive individuals",
    "Have the ability to generate their own energy and are often good at following through on tasks.",
    31,
    50,
    50,
    "Oprah Winfrey, Bill Clinton, Tiger Woods, J.K. Rowling, Steve Jobs, Jennifer Aniston, Queen Elizabeth II, Michael Jordan, Arnold Schwarzenegger, Beyoncé"
  ),
  new StaticModel(
    3,
    "Manifestor",
    "Initiators and leaders",
    "They have the power to manifest their desires and make things happen.",
    9,
    50,
    50,
    "Martin Luther King Jr., Nikola Tesla, Marilyn Monroe, Bill Gates, Madonna, Elon Musk, Dalai Lama, Richard Branson, Jim Carrey, Lady Gaga"
  ),
  new StaticModel(
    4,
    "Projector",
    "Observers and guides",
    "Projectors excel in guiding and directing the energy of others.",
    22,
    50,
    50,
    "Princess Diana, Barack Obama, Eckhart Tolle, Oprah Winfrey, Albert Einstein, Marie Curie, Dalai Lama, Warren Buffett, Queen Elizabeth II, Jane Goodall"
  ),
  new StaticModel(
    5,
    "Reflector",
    "Rare and unique individuals",
    "Reflectors have a unique perspective and are highly sensitive to their environment.",
    1,
    50,
    50,
    "Princess Diana, Marilyn Monroe, Albert Einstein, Dalai Lama, Barack Obama, Oprah Winfrey, Richard Branson, Bill Gates, Lady Gaga, Elon Musk"
  ),
];

export function findHumanDesignTypeDescription(text) {
  if (text == null) {
    return;
  }
  try {
    const normalizedText = text.toLowerCase();

    for (let i = 0; i < humanDesignTypes.length; i++) {
      const type = humanDesignTypes[i];

      if (normalizedText === type.type.toLowerCase()) {
        return type.longDescription; // Return the long description of the matched type
      }
    }
  } catch (error) { }

  return "";
}

export function findHumanDesignTypeIndex(text) {
  if (text == null) {
    return 0;
  }
  try {
    const normalizedText = text.toLowerCase();

    for (let i = 0; i < humanDesignTypes.length; i++) {
      const type = humanDesignTypes[i];
      if (normalizedText.includes(type.type.toLowerCase())) {
        return i; // Found a match, return the index
      }
    }
  } catch (error) { }

  return -1; // No match found, return -1
}

export function getHumanDesignExamples(type) {
  const humanDesign = humanDesignTypes.find((model) => model.type == type);
  if (humanDesign) {
    return humanDesign.examples;
  }
  return "humanDesign not found";
}

export function getHumanDesignPercentage(type) {
  const humanDesign = humanDesignTypes.find((model) => model.type == type);
  if (humanDesign) {
    return humanDesign.percentage;
  }
  return "humanDesign not found";
}
export function getHumanDesignMenPercentage(type) {
  const humanDesign = humanDesignTypes.find((model) => model.type == type);
  if (humanDesign) {
    return humanDesign.men;
  }
  return "humanDesign not found";
}
export function getHumanDesignWomenPercentage(type) {
  const humanDesign = humanDesignTypes.find((model) => model.type == type);
  if (humanDesign) {
    return humanDesign.women;
  }
  return "humanDesign not found";
}

export function getMbtiId(mbti) {
  const matchingModel = mbtiList.find((model) => model.type === mbti);
  return matchingModel ? matchingModel.id : null;
}

export function getMBTIShortDescription(mbtiCode) {
  const mbtiModel = mbtiList.find((model) => model.type === mbtiCode);
  if (mbtiModel) {
    return mbtiModel.shortDescription;
  }
  return "MBTI code not found";
}

export function getMBTILongDescription(mbtiCode) {
  const mbtiModel = mbtiList.find((model) => model.type === mbtiCode);
  if (mbtiModel) {
    return mbtiModel.longDescription;
  }
  return "MBTI code not found";
}

export function getMbtiExamples(mbtiCode) {
  const mbti = mbtiList.find((model) => model.type == mbtiCode);
  if (mbti) {
    return mbti.examples;
  }
  return "MBTI not found";
}

export function getMbtiPercentage(mbtiCode) {
  const mbti = mbtiList.find((model) => model.type == mbtiCode);
  if (mbti) {
    return mbti.percentage;
  }
  return "MBTI not found";
}
export function getMbtiMenPercentage(mbtiCode) {
  const mbti = mbtiList.find((model) => model.type == mbtiCode);
  if (mbti) {
    return mbti.men;
  }
  return "MBTI not found";
}
export function getMbtiWomenPercentage(mbtiCode) {
  const mbti = mbtiList.find((model) => model.type == mbtiCode);
  if (mbti) {
    return mbti.women;
  }
  return "MBTI not found";
}

export function getEnneagramSortDescription(enneagramId) {
  const enneagram = enneagramList.find((model) => model.id == enneagramId);
  if (enneagram) {
    return enneagram.shortDescription;
  }
  return "Enneagram not found";
}

export function getEnneagramType(enneagramId) {
  const enneagram = enneagramList.find((model) => model.id == enneagramId);
  if (enneagram) {
    return enneagram.type;
  }
  return "Enneagram not found";
}

export function getEnneagramLongDescription(enneagramId) {
  const enneagram = enneagramList.find((model) => model.id == enneagramId);
  if (enneagram) {
    return enneagram.longDescription;
  }
  return "Enneagram not found";
}

export function getEnneagramExamples(enneagramId) {
  const enneagram = enneagramList.find((model) => model.id == enneagramId);
  if (enneagram) {
    return enneagram.examples;
  }
  return "Enneagram not found";
}

export function getEnneagramPercentage(enneagramId) {
  const enneagram = enneagramList.find((model) => model.id == enneagramId);
  if (enneagram) {
    return enneagram.percentage;
  }
  return "Enneagram not found";
}
export function getEnneagramMenPercentage(enneagramId) {
  const enneagram = enneagramList.find((model) => model.id == enneagramId);
  if (enneagram) {
    return enneagram.men;
  }
  return "Enneagram not found";
}
export function getEnneagramWomenPercentage(enneagramId) {
  const enneagram = enneagramList.find((model) => model.id == enneagramId);
  if (enneagram) {
    return enneagram.women;
  }
  return "Enneagram not found";
}
export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem("direction")) {
      const localValue = localStorage.getItem("direction");
      if (localValue === "rtl" || localValue === "ltr") {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};
export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  try {
    localStorage.setItem("direction", direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error);
  }
};

export function convertWheelOfLifeArray(inputString) {
  if (inputString == null || inputString === "") {
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  }
  // Replace "--" with "00" and remove non-numeric characters
  let cleanedString = inputString.replace(/--/g, "00").replace(/[^0-9]/g, "");
  let inputData = [];

  for (let i = 0; i < cleanedString.length; i += 2) {
    inputData.push(parseInt(cleanedString.substring(i, i + 2), 10));
  }

  return inputData;
}

export function convertStringArray(inputString) {
  if (inputString == null || inputString === "") {
    return [];
  }

  let inputData = [];

  if (Array.isArray(inputString)) {
    return inputString;
  }
  for (let i = 0; i < inputString.length; i += 1) {
    inputData.push(parseInt(inputString.substring(i, i + 1)));
  }

  return inputData;
}

export function convertStringToIndexArray(inputString) {
  if (inputString == null || inputString === "") {
    return [];
  }

  let inputData = [];

  if (Array.isArray(inputString)) {
    return inputString;
  }
  for (let i = 0; i < inputString.length + 1; i += 1) {
    if (parseInt(inputString.substring(i, i + 1)) > 0) inputData.push(i);
  }

  return inputData;
}

export function convertSaboteurArray(inputString) {
  if (inputString == null || inputString === "") {
    // 9 saboteurs
    return [0, 0, 0, 0, 0, 0, 0, 0, 0];
  }
  // Replace "---" with "0.0" and remove non-numeric characters
  let cleanedString = inputString.replace(/---/g, "0.0");
  let inputData = [];

  for (let i = 0; i < cleanedString.length; i += 3) {
    inputData.push(parseFloat(cleanedString.substring(i, i + 3)));
  }

  return inputData;
}


// function safeJSONParse(jsonString) {
//   try {
//     // Step 1: Unescape JSON strings to handle double-escaped characters
//     const unescapedJSON = jsonString.replace(/\\./g, (match) => {
//       switch (match) {
//         case '\\"': return '"';
//         case '\\n': return '';
//         case '\\t': return '';
//         // case '\t': return '';
//         // case '\n': return '';
//         // Add more escape sequences as needed
//         default: return match[1]; // Remove the backslash
//       }
//     });

//     // Step 2: Parse the unescaped JSON
//     const parsedData = JSON.parse(unescapedJSON);

//     return parsedData;
//   } catch (error) {
//     console.error('Error parsing JSON:', error);
//     return null; // Handle the error gracefully or throw an exception if necessary
//   }
// }

export function getProfileSummaryObject(data) {
  const result = {};
  if (data == null || data === "") {
    return;
  }

  return data["summary"];
}

export function getPhilosophyResultObject(data) {
  const result = {};
  if (data == null || data === "") {
    return;
  }

  for (let i = 1; i <= 3; i++) {
    const optionKey = `option${i}`;

    if (data[optionKey]) {
      result[optionKey] = {
        title: data[optionKey].title,
        keyBeliefs: data[optionKey].keyBeliefs,
        // keyDifferentiatingPoints: data[optionKey].keyDifferentiatingPoints.map(
        // (point) => point.point
        // ),
        reasoning: data[optionKey].reasoning,
      };
    }
  }
  return result;
}

export function splitStringToList(inputString) {
  if (typeof inputString !== "string") {
    return []; // Return an empty list if the input is not a string
  }

  // Split the input string by commas and trim whitespace from each part
  const stringList = inputString.split(",").map((item) => item.trim());

  return stringList;
}
export const updateCurrentUser = (json) => {
  const currentUser = getCurrentUser();

  currentUser.id = json.id;
  currentUser.name = json.name;
  currentUser.email = json.email;
  currentUser.terms = json.terms;
  currentUser.feedback = json.feedback;
  currentUser.communication = json.communication;

  currentUser.mbti = json.mbti;
  currentUser.saboteurs = json.saboteurs;
  currentUser.enneagram = json.enneagram;
  currentUser.userValues = json.userValues;
  currentUser.userStrengths = json.userStrengths;
  currentUser.birthDate = json.birthDate;
  currentUser.birthHour = json.birthHour;
  currentUser.placeOfBirth = json.placeOfBirth;
  currentUser.timezone = json.timezone;
  currentUser.interests = json.interests;
  currentUser.wheelOfLife = json.wheelOfLife;
  currentUser.otherData = json.otherData;
  currentUser.humanDesign = json.humanDesign;
  currentUser.bodygraph = json.bodygraph;
  currentUser.bodygraphFull = json.bodygraphFull;
  currentUser.domainsMixes = json.domainsMixes;

  currentUser.odyssey1 = json.odyssey1;
  currentUser.odyssey2 = json.odyssey2;
  currentUser.odyssey3 = json.odyssey3;

  currentUser.odysseyNotes1 = json.odysseyNotes1;
  currentUser.odysseyNotes2 = json.odysseyNotes2;
  currentUser.odysseyNotes3 = json.odysseyNotes3;
  currentUser.keyQuestions1 = json.keyQuestions1;
  currentUser.keyQuestions2 = json.keyQuestions2;
  currentUser.keyQuestions3 = json.keyQuestions3;
  currentUser.createdAt = json.createdAt;
  currentUser.updatedAt = json.updatedAt;
  currentUser.promptTokens = json.promptTokens;
  currentUser.sampledTokens = json.sampledTokens;
  currentUser.keyQuestionsExample1 = json.keyQuestionsExample1;
  currentUser.keyQuestionsExample2 = json.keyQuestionsExample2;
  currentUser.keyQuestionsExample3 = json.keyQuestionsExample3;

  currentUser.domains = json.domains;

  currentUser.selectedDomains = json.selectedDomains;

  currentUser.possibleLives = json.possibleLives;
  currentUser.profileSummary = json.profileSummary;
  currentUser.profileSummaryFull = json.profileSummaryFull;
  currentUser.advice = json.advice;
  currentUser.lifePhilosophy = json.lifePhilosophy;
  currentUser.workPhilosophy = json.workPhilosophy;
  currentUser.clash = json.clash;
  currentUser.complement = json.complement;
  currentUser.lifePhilosophyRefined = json.lifePhilosophyRefined;
  currentUser.workPhilosophyRefined = json.workPhilosophyRefined;
  currentUser.careerRecommendations = json.careerRecommendations;

  currentUser.hasChanged = false;

  setCurrentUser(currentUser);
};

export class UserRelationship {
  constructor(data) {
    this.id = data.id;
    if (getCurrentUser().id === data.id1) {
      this.friendId = data.id2;
    } else {
      this.friendId = data.id1;
    }

    this.type = data.type;
    this.score = data.score;
    this.alignment = data.alignment;
    this.clash = data.clash;
  }
}

export function getConnectionResultObject(data) {
  const result = {};

  if (data == null || data === "") {
    return;
  }

  for (let i = 1; i <= 3; i++) {
    const optionKey = `paragraph${i}`;
    if (data[optionKey]) {
      result[optionKey] = {
        topic: data[optionKey].topic,
        score: data[optionKey].score,
        reasoning: data[optionKey].reasoning,
        example: data[optionKey].example,
      };
    }
  }

  return result;
}

export class ConnectionResultObject {
  constructor() {
    this.paragraph1 = new ConnectionParagraph("", "", "", "");
    this.paragraph2 = new ConnectionParagraph("", "", "", "");
    this.paragraph3 = new ConnectionParagraph("", "", "", "");
  }
}

export class ConnectionParagraph {
  constructor(topic, score, reasoning, example) {
    this.topic = topic;
    this.score = score;
    this.reasoning = reasoning;
    this.example = example;
  }
}

export function removeJsonCodeBlocks(text) {
  if (text == null) {
    return null;
  }
  try {
    // Use regular expressions to remove ```json and ``` code block formatting
    const cleanedText = text.replace(/```json/g, "").replace(/```/g, "");

    return JSON.parse(cleanedText);
  } catch (error) { }

  return text;
}

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = "rounded";
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentRadius -> error",
      error
    );
    currentRadius = "rounded";
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentRadius -> error",
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem("currentLanguage") &&
        localeOptions.filter(
          (x) => x.id === localStorage.getItem("currentLanguage")
        ).length > 0
        ? localStorage.getItem("currentLanguage")
        : defaultLocale;
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : getCurrentLanguage -> error",
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem("currentLanguage", locale);
  } catch (error) {
    console.log(
      ">>>>: src/helpers/Utils.js : setCurrentLanguage -> error",
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem("agl_current_user") != null
        ? JSON.parse(localStorage.getItem("agl_current_user"))
        : null;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
    user = null;
  }

  return user;
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function typeWriter(txt, id) {
  var i = 0;
  var speed = 50;

  if (i < txt.length) {
    document.getElementById(id).innerHTML += txt.charAt(i);
    i++;
    setTimeout(typeWriter, speed);
  }
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem("agl_current_user", JSON.stringify(user));
    } else {
      localStorage.removeItem("agl_current_user");
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
  }
};
