import {
  FRIEND_LIST_GET_LIST,
  FRIEND_LIST_GET_LIST_SUCCESS,
  FRIEND_LIST_GET_LIST_ERROR,
  FRIEND_LIST_GET_LIST_WITH_FILTER,
  FRIEND_LIST_GET_LIST_WITH_ORDER,
  FRIEND_LIST_GET_LIST_SEARCH,
  FRIEND_LIST_ADD_ITEM,
  FRIEND_LIST_ADD_ITEM_SUCCESS,
  FRIEND_LIST_ADD_ITEM_ERROR,
  FRIEND_LIST_SELECTED_ITEMS_CHANGE,
} from '../contants';

const INIT_STATE = {
  allFriendItems: null,
  friendItems: null,
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [
    { label: 'FRIEDNSHIP', color: 'secondary' },
    { label: 'BUSINESS', color: 'primary' },
    { label: 'RELATIONSHIP', color: 'info' },
  ],
  orderColumns: [
    { column: 'name', label: 'Name' },
    { column: 'friendship', label: 'Friendship' },
    { column: 'business', label: 'Business' },
    { column: 'relationship', label: 'Relationship' },
  ],
  categories: ['Friendship', 'Business', 'Relationship'],
  selectedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FRIEND_LIST_GET_LIST:
      return { ...state, loading: false };

    case FRIEND_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: true,
        allFriendItems: action.payload,
        friendItems: action.payload,
      };

    case FRIEND_LIST_GET_LIST_ERROR:
      return { ...state, loading: true, error: action.payload };

    case FRIEND_LIST_GET_LIST_WITH_FILTER:
      if (action.payload.column === '' || action.payload.value === '') {
        return {
          ...state,
          loading: true,
          friendItems: state.allFriendItems,
          filter: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const filteredItems = state.allFriendItems.filter(
        (item) => item[action.payload.column] === action.payload.value
      );
      return {
        ...state,
        loading: true,
        friendItems: filteredItems,
        filter: {
          column: action.payload.column,
          value: action.payload.value,
        },
      };

    case FRIEND_LIST_GET_LIST_WITH_ORDER:
      if (action.payload === '') {
        return {
          ...state,
          loading: true,
          friendItems: state.friendItems,
          orderColumn: null,
        };
      }
      // eslint-disable-next-line no-case-declarations
      const sortedItems = state.friendItems.sort((a, b) => {
        if (a[action.payload] < b[action.payload]) return -1;
        if (a[action.payload] > b[action.payload]) return 1;
        return 0;
      });
      return {
        ...state,
        loading: true,
        friendItems: sortedItems,
        orderColumn: state.orderColumns.find(
          (x) => x.column === action.payload
        ),
      };

    case FRIEND_LIST_GET_LIST_SEARCH:
      if (action.payload === '') {
        return { ...state, friendItems: state.allFriendItems };
      }
      // eslint-disable-next-line no-case-declarations
      const keyword = action.payload.toLowerCase();
      // eslint-disable-next-line no-case-declarations
      const searchItems = state.allFriendItems.filter(
        (item) =>
          item.name.toLowerCase().indexOf(keyword) > -1 
          // ||
          // item.detail.toLowerCase().indexOf(keyword) > -1 ||
          // item.status.toLowerCase().indexOf(keyword) > -1 ||
          // item.category.toLowerCase().indexOf(keyword) > -1 ||
          // item.label.toLowerCase().indexOf(keyword) > -1
      );
      return {
        ...state,
        loading: true,
        friendItems: searchItems,
        searchKeyword: action.payload,
      };

    case FRIEND_LIST_ADD_ITEM:
      return { ...state, loading: false };

    case FRIEND_LIST_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: true,
        allFriendItems: action.payload,
        friendItems: action.payload,
      };

    case FRIEND_LIST_ADD_ITEM_ERROR:
      return { ...state, loading: true, error: action.payload };

    case FRIEND_LIST_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: true, selectedItems: action.payload };
    default:
      return { ...state };
  }
};
