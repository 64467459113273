
export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyDgDYZhx6eBjLlkTa-KqHsHs1qf3GjqRxw",
  authDomain: "agoodlife-dfb60.firebaseapp.com",
  projectId: "agoodlife-dfb60",
  storageBucket: "agoodlife-dfb60.appspot.com",
  messagingSenderId: "12380930589",
  appId: "1:12380930589:web:7e307e0bd15cc7ace27cf4",
  measurementId: "G-FN6ES76292",
  // databaseURL:"https://agoodlife-dfb60-default-rtdb.europe-west1.firebasedatabase.app/"
};

export const adminRoot = "/app";
export const searchPath = `${adminRoot}/#`;
export const servicePath = "https://api2.agoodlife.io";



export const currentUser = {
  id: 0,
  title: "",
  img: "/assets/img/profiles/profile.png",
  birthDate: "",
  birthHour: "",
  placeOfBirth: "",
  timezone: "",
  interests: "",
  wheelOfLife: "",
  lifePhilosophy: "",
  workPhilosophy: "",
  careerRecommendations: "",
  otherData: "",
  profileSummary: "",
  profileSummaryParagraph1:"",
  date: "",
  humanDesign: "",
  friends: [],
  role: UserRole.Admin,
};


export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = true;
export const defaultColor = "light.purplemonster";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];
