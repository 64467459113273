import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import friendListApp from './friendList/reducer';
import groupListApp from './friendList/reducer';
import friendDetailApp from './friendDetail/reducer';
import groupDetailpp from './friendDetail/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  friendListApp,
  groupListApp,
  groupDetailpp,
  friendDetailApp,
});

export default reducers;
