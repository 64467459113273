import {
  FRIEND_GET_DETAILS,
  FRIEND_GET_DETAILS_SUCCESS,
  FRIEND_GET_DETAILS_ERROR,
  FRIEND_SAVE,
} from '../contants';

const INIT_STATE = {
  friend: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FRIEND_GET_DETAILS:
      return { ...state, loading: false };

    case FRIEND_GET_DETAILS_SUCCESS:
      return { ...state, loading: true, friend: action.payload };

    case FRIEND_GET_DETAILS_ERROR:
      return { ...state, loading: true, error: action.payload };

    case FRIEND_SAVE:
      return { ...state, loading: true, friend: action.payload };

    default:
      return { ...state };
  }
};
